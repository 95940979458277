const title = ({ title }) => {
  return (
    <div className="flex justify-center uppercase mb-10">
      <h1 className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-orange-500 text-4xl font-extrabold mt-4 mx-4">
        {title}
      </h1>
    </div>
  );
};

export default title;
