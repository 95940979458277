const Contact = ({ label }) => {
  return (
    <div id="contacts" className="flex justify-center">
      <a
        href={
          label === "Resume"
            ? "https://drive.google.com/drive/folders/12-1KAhN4DRs7GP7DYWiCOQF2I5C9L2DG?usp=sharing"
            : "mailto: utkarshkumar178@gmail.com"
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <button
          type="button"
          class="text-gray-800 mt-16 mb-10 text-3xl font-bold p-4 rounded-md bg-gradient-to-r from-orange-500 to-yellow-500 hover:bg-gradient-to-r hover:from-yellow-500 hover:via-orange-400 hover:to-orange-500 hover:scale-105 transition ease-in-out active:bg-orange-700"
        >
          {label}
        </button>
      </a>
    </div>
  );
};

export default Contact;
