import {
  SiHtml5,
  SiCss3,
  SiTailwindcss,
  SiJavascript,
  SiTypescript,
  SiReact,
  SiGithub,
  SiSass,
  SiNextdotjs,
} from "react-icons/si";

const Skills = () => {
  return (
    <div id="skills">
      <ul className="flex flex-row justify-center flex-wrap">
        <li>
          <SiHtml5 className="text-5xl sm:text-7xl mx-5 sm:mx-10 mb-4 sm:mb-10 mt-10 hover:text-orange-500 hover:animate-pulse hover:scale-150 transition ease-in-out duration-300" />
        </li>
        <li>
          <SiCss3 className="text-5xl sm:text-7xl mx-5 sm:mx-10 mb-4 sm:mb-10 mt-10 hover:text-blue-600 hover:animate-pulse hover:scale-150 transition ease-in-out duration-300" />
        </li>
        <li>
          <SiJavascript className="text-5xl sm:text-7xl mx-5 sm:mx-10 mb-4 sm:mb-10 mt-10 hover:text-yellow-500 hover:animate-pulse hover:scale-150 transition ease-in-out duration-300" />
        </li>
        <li>
          <SiTypescript className="text-5xl sm:text-7xl mx-5 sm:mx-10 mb-4 sm:mb-10 mt-10 hover:text-blue-400 hover:animate-pulse hover:scale-150 transition ease-in-out duration-300" />
        </li>
        <li>
          <SiReact className="text-5xl sm:text-7xl mx-5 sm:mx-10 mb-4 sm:mb-10 mt-10 hover:text-cyan-500 hover:animate-pulse hover:scale-150 transition ease-in-out duration-300" />
        </li>
        <li>
          <SiNextdotjs className="text-5xl sm:text-7xl mx-5 sm:mx-10 mb-4 sm:mb-10 mt-10 hover:text-black hover:animate-pulse hover:scale-150 transition ease-in-out duration-300" />
        </li>
        <li>
          <SiTailwindcss className="text-5xl sm:text-7xl mx-5 sm:mx-10 mb-4 sm:mb-10 mt-10 hover:text-teal-500 hover:animate-pulse hover:scale-150 transition ease-in-out duration-300" />
        </li>
        <li>
          <SiSass className="text-5xl sm:text-7xl mx-5 sm:mx-10 mb-4 sm:mb-10 mt-10 hover:text-pink-500 hover:animate-pulse hover:scale-150 transition ease-in-out duration-300" />
        </li>
        <li>
          <SiGithub className="text-5xl sm:text-7xl mx-5 sm:mx-10 mb-4 sm:mb-10 mt-10 hover:text-white hover:animate-pulse hover:scale-150 transition ease-in-out duration-300" />
        </li>
      </ul>
    </div>
  );
};

export default Skills;
