import { CgWebsite } from "react-icons/cg";
import {
  SiNextdotjs,
  SiTailwindcss,
  SiTypescript,
  SiReact,
} from "react-icons/si";

const Projects = ({ name }) => {
  return (
    <div id="projects" className="flex flex-row flex-wrap justify-center">
      <div className="border-2 border-yellow-400 p-20 mx-2 sm:mx-10 mb-6 sm:max-h-fit flex flex-col justify-center text-center items-center">
        <h1 className="sm:text-5xl text-2xl font-bold uppercase pb-2">
          {name}
        </h1>
        <div className="text-lg my-5">
          {name === "the trailer machine" ? (
            <p>This Application allows you to explore movies and TV shows</p>
          ) : (
            <p>In the mood for some Trivia? You are the right place</p>
          )}
        </div>
        <p className="uppercase font-semibold mt-6">Tech:</p>
        <div className="mt-6">
          {name === "the trailer machine" ? (
            <div className="text-6xl flex">
              <SiNextdotjs className="mx-2" />
              <SiTailwindcss className="mx-2" />
            </div>
          ) : (
            <div className="text-6xl flex flex-wrap justify-center">
              <SiReact className="mr-2 mt-2" />
              <SiTailwindcss className="mr-2 mt-2" />
              <SiTypescript className="mr-2 mt-2" />
            </div>
          )}
        </div>
        <p className="uppercase font-semibold mt-10">Live Site:</p>
        <a
          href={
            name === "the trailer machine"
              ? "https://trailer-machine.vercel.app"
              : "https://trivia-mu.vercel.app"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <CgWebsite className="text-5xl mt-2" />
        </a>
      </div>
    </div>
  );
};

export default Projects;
