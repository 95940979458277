const Navbar = () => {
  return (
    <div className="flex justify-center max-w-full mt-2">
      <div className="uppercase flex flex-col sm:flex-row justify-between flex-wrap items-center sm:min-w-[640px] md:min-w-[768px] 2xl:min-w-[80rem] max-w-7xl">
        <div className="cursor-pointer hover:-translate-y-0.5 transform transition">
          <h1 className="bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-orange-500 text-4xl font-extrabold mt-4 mx-4 hover:animate-spin">
            UK
          </h1>
        </div>
        <ul className="flex flex-row-reverse mt-4 mx-4">
          <li
            key={3}
            className="font-bold mt-4 mx-4 cursor-pointer hover:text-orange-500 transform transition active:text-orange-700 animate hover:animate-bounce"
          >
            <a href="#contacts">Contacts</a>
          </li>
          <li
            key={2}
            className="font-bold mt-4 mx-4 cursor-pointer hover:text-orange-500 hover:animate-bounce transform transition active:text-orange-700"
          >
            <a href="#skills">Skills</a>
          </li>
          <li
            key={1}
            className="font-bold mt-4 mx-4 cursor-pointer hover:text-orange-500 hover:animate-bounce transform transition active:text-orange-700"
          >
            <a href="#projects">Projects</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
