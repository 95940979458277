const Landing = () => {
  return (
    <div className="flex w-full justify-center">
      <div className="flex flex-wrap flex-col justify-center items-center mx-4 mt-10 sm:mt-24 max-w-7xl xl:min-w-[80rem] p-3">
        <h1 className="text-center font-extrabold text-7xl mb-12 break-words">
          Hi<span className="text-orange-500">,</span> I am Utkarsh Kumar
          <span className="text-orange-500">.</span>
        </h1>
        <p className="text-2xl sm:text-xl max-w-3xl text-center break-word">
          I have a major in Technology and Information Management with a passion
          for Front End Development. I possess working knowledge of HTML/CSS,
          JavaScript, and REACT with passion for aesthetic and responsive web
          design. Adept at working in a highly collaborative environment,
          performing in-depth analysis, and finding tailored solution to achieve
          customer satisfaction.
        </p>
      </div>
    </div>
  );
};

export default Landing;
