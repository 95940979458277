import "./App.css";
import Navbar from "./Components/Navbar";
import Landing from "./Components/Landing";
import Skills from "./Components/Skills";
import Contact from "./Components/Contact";
import Project from "./Components/Project";
import Title from "./Components/Title";
import { SiLinkedin } from "react-icons/si";

function App() {
  return (
    <div className="min-h-screen min-w-screen text-yellow-500">
      <Navbar />
      <Landing />
      <Contact label={"Resume"} />
      <Title title={"Projects"} />
      <div className="flex flex-wrap justify-center mb-20">
        <Project name={"the trailer machine"} />
        <Project name={"the trivia machine"} />
      </div>
      <Title title={"skills"} />
      <Skills />
      <div className="flex justify-center flex-row flex-wrap">
        <Contact label={"Contact Me!"} />
        <a href="https://www.linkedin.com/in/ut-kumar">
          <SiLinkedin className="text-[4.2rem] my-[4rem] mx-5 hover:text-orange-500 active:text-orange-700"></SiLinkedin>
        </a>
      </div>
    </div>
  );
}

export default App;
